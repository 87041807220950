<template>
  <edit-template class="edit" @confirm="submit" :confirm-loading="loading" type="card" :data-loading="dataLoading">
    <edit-card title="教材基础信息">
      <el-form :model="form" ref="form" label-width="120rem" :rules="rules">
<!--        所属学科:select-->
        <el-form-item label="学科">{{ subject_name }}</el-form-item>
<!--        教材名称:select-->
        <el-form-item label="教材名称">{{ teach_name }}</el-form-item>
      </el-form>
    </edit-card>
    <edit-card title="章节内容">
      <editable-tree
          ref="tree"
          allow-empty
          node-default="章节"
          :max-level="4"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :level-rule="levelRule"
          :props="defaultProps"
          :data="data">
      </editable-tree>
    </edit-card>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit";
import EditCard from "@/components/pages/EditCard";
import EditableTree from "@/components/EditableTree";
import { zhGet, zhPut } from '@/api'

export default {
  _config: {route: {path: "edit", meta: {title: "编辑",style:2}}},
  components: {EditTemplate,EditCard,EditableTree},
  data() {
    return {
      subject_name: '',
      teach_name: '',
      loading: false,
      dataLoading:false,
      defaultProps:{
        label:"article_name",
        children:"article_list"
      },
      levelRule:[
          // 一级子树
          {
            editable:false, // 是否可编辑
            onlyDeleteChild:true, // 是否只删除子节点
            maxLength:15,    // 子节点最大长度, 设为-1 表示不限制
          },
          // 二级子树
          {
            maxLength: 10
          },
          // 三级子树
          {
            maxLength: 5
          },

      ],
      form: {
        subject_id: null,
        apply_type: null,
      },
      // 表单验证规则
      rules: {
        subject_id: [
          {required: true, message: '请选择所属学科', trigger: 'change'},
        ],
        apply_type: [
          {required: true, message: '请选择应用学段', trigger: 'change'},
        ]
      },
      //章节内容数据
      data:[
        {
          article_name: '章节',
          key:this.$tools.GUID(),
          article_list: []
        }
      ],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.dataLoading=true
      zhGet('/video/get-article', { id: this.$route.query.id }).then(res => {
        const { subject_name, teach_name, article_basic_id, article_list } = res
        this.subject_name = subject_name
        this.teach_name = teach_name
        this.article_basic_id = article_basic_id
        this.$tools.DFS(article_list,item=>{
          item.key = this.$tools.GUID();
        },{
          child:"article_list"
        })
        this.data = article_list;
        this.setRootName();
      }).finally(()=>this.dataLoading=false)
    },
    submit() {
      this.$refs.form.validate(flag=>{
        if (flag) {
          this.$tools.DFS(this.data,item=>{
            item.level=item._tier+1;
            if (item.article_list){
              item.article_list.forEach((node,index)=>{
                node.order = index;
                node.pid = item.id || undefined;
              })
            }
          },{
            child:"article_list"
          })
          const data = {
            id:this.$route.query.id,
            article_basic_id:this.article_basic_id,
            article_list:this.data,
          }
          this.loading = true
          zhPut("/video/edit-article", data).then(()=>{
            this.$setPrevOperation("edit");
            this.$router.back();
            this.$message.success("编辑成功")
          }).finally(()=>this.loading=false)
        }
      })

    },
    // 设置根节点的名称
    setRootName(){
      let book = this.teach_name ? `${this.teach_name}-` : "";
      let subject = this.subject_name ? `${this.subject_name}-` : "";
      this.data[0][this.defaultProps.label] = `${subject} ${book} 章节`
    },
    // 判断节点能否被放置
    allowDrop(draggingNode, dropNode, type) {
      let curParentId = draggingNode.parent.id;
      let parentId = dropNode.parent.id;
      return parentId === curParentId && type !== 'inner';
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.level !== 1;
    }

  }
}
</script>

<style lang="scss" scoped>
.edit{

}
</style>
